/**ct
 * 后端：yank;
 * 前端：xmen;
 * 登录接口地址：http://gw.dev.douqiu.run/doc.html#/qiutx-passport/OAuth2%E7%9B%B8%E5%85%B3%E6%93%8D%E4%BD%9C/agentLoginUsingPOST
 */
import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { httpService } from '../../common/services/http.service';
import { storageService } from '../../common/services/storage.service';
import  CopyRight  from '../../common/components/Copyright';
import '../style/login-home-page.less';
import logo from '../../../assets/login/houtai-logo@3x.png';
import lockImg from '../../../assets/login/houtai-mima@2x.png';
import peopleImg from '../../../assets/login/houtai-zhanghu@2x.png';
import closeLeft from '../../../assets/login/houtai-shanchu@2x.png';
import closeRight from '../../../assets/login/houtai-chacha@2x.png';
const FormItem = Form.Item;

const LoginHomePage = (props: any) => {
    const [errorMsg, seterrorMsg] = useState('');
    const [loading, setloading] = useState(false);
    const history = useHistory();
    const handleSubmit = (values: any) => {
        let params = values;
        setloading(true);
        storageService.clear();
        httpService
            .ajax({
                url: '/qiutx-passport/app/login',
                method: 'post',
                body: { ...params, type: 'agent' },
            })
            .subscribe(
                (res: any) => {
                    if (res.code == 200) {
                        storageService.setItem('user', JSON.stringify(res.data));
                        storageService.setItem('token', res.data?.token);
                        storageService.setItem('uid', res.data?.uid);
                        storageService.setItem('agentName', res.data?.agentName);
                        message.success('登录成功');
                        window.open('/#/app/statistics','_self')
                    } else {
                        seterrorMsg(res.msg);
                        return message.error('登录失败');
                    }
                },
                () => {},
                () => {
                    setloading(false);
                }
            );
    };
    const hideError = () => {
        seterrorMsg('');
    };
    let formValueChange = () => {
        if (errorMsg) seterrorMsg('');
    };
    return (
        <div className="login-home-page">
            <div className="login-wrap">
                <div className="color-block">
                    <span></span>
                </div>
                <div className="title-wrap">
                    <div className="logo">
                        <img src={logo} />
                    </div>
                    <div className="title">代理商后台</div>
                </div>
                <div className="error-message-wrap">
                    {errorMsg && (
                        <div className="error-message">
                            <img onClick={hideError} src={closeLeft} className="close-left" />
                            <span className="text">{errorMsg}</span>
                            <img onClick={hideError} src={closeRight} className="close-right" />
                        </div>
                    )}
                </div>
                <div className="login-form-wrap">
                    <Form onFinish={handleSubmit} style={{ maxWidth: '300px' }}>
                        <FormItem name="userName">
                            <Input
                                style={{ width: '368px' }}
                                size="large"
                                placeholder="账号"
                                prefix={<img src={peopleImg} />}
                            />
                        </FormItem>
                        <FormItem name="passWord">
                            <Input.Password
                                style={{ width: '368px' }}
                                size="large"
                                placeholder="密码"
                                prefix={<img src={lockImg} />}
                            />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" loading={loading} htmlType="submit">
                                登录
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
            <div className="footer">
                <CopyRight />
            </div>
        </div>
    );
};

export default LoginHomePage;
