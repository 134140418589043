/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react';
import { Table, Button, Row, Col, Card, Form, DatePicker, Typography, message, Select } from 'antd';
import { storageService } from '../../modules/common/services/storage.service';
import './style/visit.less';
import moment from 'moment';
import { httpService } from '../../modules/common/services/http.service';
interface visitSubtotalElement {
    webVisits: number;
    h5Visits: number;
    h5Registration: number;
    iosRegistration: number;
    androidRegistration: number;
    iosInstalled: number;
    androidInstalled: number;
    totalNumberOfRegistrations: number;
    agentNameMyself: string;
}
interface agentListItem {
    userId: string | number;
    agentName: string | number;
}
const { RangePicker } = DatePicker;
const { Text } = Typography;
const columns = [
    {
        title: '代理',
        dataIndex: 'agentName',
        width: 100,
        render: (text: any, record: any) => (
            <a href={record.url} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        ),
    },
    {
        title: 'web访问',
        dataIndex: 'webVisits',
        width: 120,
    },
    {
        title: 'h5访问',
        dataIndex: 'h5Visits',
        width: 120,
    },
    {
        title: 'h5注册量',
        width: 120,
        dataIndex: 'h5Registration',
    },
    {
        title: 'IOS注册量',
        width: 120,
        dataIndex: 'iosRegistration',
    },
    {
        width: 120,
        title: '安卓注册量',
        dataIndex: 'androidRegistration',
    },
    {
        width: 120,
        title: 'IOS安装量',
        dataIndex: 'iosInstalled',
    },
    {
        width: 120,
        title: '安卓安装量',
        dataIndex: 'androidInstalled',
    },
    {
        title: '注册总数',
        width: 120,
        dataIndex: 'totalNumberOfRegistrations',
    },
];
const daylyColumns = [
    {
        title: '时间',
        dataIndex: 'date',
        width: 120,
    },
    {
        title: '代理',
        dataIndex: 'agentName',
        width: 120,
        render: (text: any, record: any) => (
            <a href={record.url} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        ),
    },
    {
        title: 'web访问',
        dataIndex: 'webVisits',
        width: 120,
    },
    {
        title: 'h5访问',
        dataIndex: 'h5Visits',
        width: 120,
    },
    {
        title: 'h5注册量',
        width: 120,
        dataIndex: 'h5Registration',
    },
    {
        title: 'IOS注册量',
        width: 120,
        dataIndex: 'iosRegistration',
    },
    {
        title: '安卓注册量',
        width: 120,
        dataIndex: 'androidRegistration',
    },
    {
        title: 'IOS安装量',
        width: 120,
        dataIndex: 'iosInstalled',
    },
    {
        title: '安卓安装量',
        width: 120,
        dataIndex: 'androidInstalled',
    },
    {
        width: 120,
        title: '注册总数',
        dataIndex: 'totalNumberOfRegistrations',
    },
];

class Visit extends React.Component {
    state = {
        loading: false,
        totalData: [],
        daylyData: [],
        visitSubtotal: [],
        startTime: '',
        endTime: '',
        datePickList: [
            { date: '今日', dateNum: 0 },
            { date: '昨日', dateNum: -1 },
            { date: '近3天', dateNum: -2 },
            { date: '近7天', dateNum: -6 },
            { date: '近30天', dateNum: -29 },
        ],
        cur: 3,
        agentList: [],
        agentName: '',
        agentNameMyself: '',
    };

    componentDidMount() {
        /**
         * @param 初始化加载数据
         */
        this.selectDate(-6, 3);
        this.getAgentList();
    }
    getAgentList = () => {
        let uid = storageService.getItem('uid');
        let agentNameMyself = storageService.getItem('agentName');
        this.setState({ agentNameMyself });
        httpService
            .ajax({
                url: `/qiutx-support/v1/agent/check/rights`,
                method: 'get',
                headers: uid
                    ? {
                          'x-user-header': JSON.stringify({ uid }),
                      }
                    : {},
            })
            .subscribe((res: any) => {
                if (res.code !== 200) return message.error(res.msg);
                this.setState({ agentList: res.data });
            });
    };
    /**
     * @param 获取数据
     */
    queryData = (): void => {
        let { startTime, endTime } = this.state;
        if (startTime) startTime = startTime + ` 00:00:00`;
        if (endTime) endTime = endTime + ` 23:59:59`;

        let uid = storageService.getItem('uid');
        httpService
            .ajax({
                url: `/qiutx-support/v1/agent/reg/data/summary?startTime=${startTime}&endTime=${endTime}&agentName=${this.state.agentName}`,
                method: 'get',
                headers: uid
                    ? {
                          'x-user-header': JSON.stringify({ uid }),
                      }
                    : {},
            })
            .subscribe((res: any) => {
                if (res.code !== 200) return message.error(res.msg);
                this.setState({
                    totalData: res.data?.page.list || [],
                    visitSubtotal: [res.data?.statisticalSubtotal] || [],
                    loading: false,
                });
            });
        /**
         * @param 查询数据
         */
        httpService
            .ajax({
                url: `/qiutx-support/v1/agent/reg/day/data/summary?startTime=${startTime}&endTime=${endTime}&agentName=${this.state.agentName}`,
                method: 'get',
                headers: uid
                    ? {
                          'x-user-header': JSON.stringify({ uid }),
                      }
                    : {},
            })
            .subscribe((res: any) => {
                if (res.code !== 200) return message.error(res.msg);
                this.setState({
                    daylyData: res.data.list || [],
                    loading: false,
                });
            });
    };

    /**
     * @param  数据汇总页脚
     */
    footerRander = () => {
        const { visitSubtotal } = this.state;
        return visitSubtotal.map((el: visitSubtotalElement, index) => (
            <Table.Summary.Row key={index}>
                <Table.Summary.Cell index={0}>总计</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    <Text>{el.webVisits}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <Text>{el.h5Visits}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3}>
                    <Text>{el.h5Registration}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={4}>
                    <Text>{el.iosRegistration}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={5}>
                    <Text>{+el?.androidRegistration}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={6}>
                    <Text>{el?.iosInstalled}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={7}>
                    <Text>{el.androidInstalled}</Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={8}>
                    <Text>{el.totalNumberOfRegistrations}</Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        ));
    };

    renderPickTime = () => (
        <div className="datePick_container">
            选择日期：
            {this.state.datePickList.map((item, index) => (
                <div
                    key={index}
                    className={`datePick ${this.state.cur === index ? 'cur' : ''}`}
                    onClick={() => this.selectDate(item.dateNum, index)}
                >
                    {item.date}
                </div>
            ))}
        </div>
    );

    selectDate(num?: number, index?: number) {
        function format2(num: number) {
            return Number(num) < 10 ? `0${num}` : num;
        }

        function getMoveDay(AddDayCount: number) {
            const today = new Date();
            today.setDate(today.getDate() + AddDayCount);
            return `${format2(today.getFullYear())}-${format2(today.getMonth() + 1)}-${format2(
                today.getDate()
            )}`;
        }
        if (num || num === 0) {
            if (num === -1)
                this.setState(
                    (state) => {
                        return {
                            startTime: getMoveDay(num),
                            endTime: getMoveDay(num),
                            cur: 1,
                        };
                    },
                    () => this.queryData()
                );
            else
                this.setState(
                    (state) => {
                        return {
                            cur: index,
                            startTime: getMoveDay(num),
                            endTime: moment().format('YYYY-MM-DD'),
                        };
                    },
                    () => this.queryData()
                );
        } else
            this.setState(
                (state) => {
                    return {
                        startTime: '',
                        endTime: '',
                        cur: null,
                    };
                },
                () => this.queryData()
            );
    }
    setTime = (dates: any, dateStrings: [string, string]): void => {
        let [startTime, endTime] = dateStrings;
        this.setState(
            (state) => {
                return {
                    startTime,
                    endTime,
                };
            },
            () => {}
        );
    };
    selectAgent = (value: any) => {
        if (!value) return;
        this.setState({ agentName: value });
    };
    render() {
        const { loading, startTime, endTime, daylyData, totalData } = this.state;
        let showTime: any = [moment(startTime), moment(endTime)];
        if (!startTime || !endTime) showTime = ['', ''];
        const { Option } = Select;
        return (
            <>
                <div className="gutter-example statistics">
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24}>
                            <div
                                className="gutter-box"
                                style={{ padding: `0 32px`, background: '#fff' }}
                            >
                                <Card bordered={false} style={{ padding: '0 !important' }}>
                                    <Form onFinish={this.queryData} className="form-box">
                                        <div className="flexBetween">
                                            <div className="selectTime">
                                                {this.renderPickTime()}
                                                {startTime && endTime && (
                                                    <div className="desc">
                                                        {`已筛选结果：` +
                                                            startTime +
                                                            ' 00:00:00' +
                                                            '~' +
                                                            endTime +
                                                            ' 23:59:59'}
                                                    </div>
                                                )}
                                            </div>

                                            <RangePicker
                                                onChange={this.setTime}
                                                value={showTime}
                                                style={{ height: '32px', width: '256px' }}
                                            />
                                        </div>
                                        <div className="select-agent margin-left20">
                                            代理号:
                                            <Select
                                                style={{ width: '150px', marginLeft: '5px' }}
                                                value={this.state.agentName}
                                                onSelect={this.selectAgent}
                                            >
                                                <Option value={this.state.agentNameMyself}>
                                                    {this.state.agentNameMyself}
                                                </Option>
                                                {this.state.agentList &&
                                                    this.state.agentList.map(
                                                        (item: agentListItem, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    value={item.agentName}
                                                                >
                                                                    {item.agentName}
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                            </Select>
                                        </div>
                                        <div className="margin-left20">
                                            <Button
                                                type="primary"
                                                disabled={loading}
                                                loading={loading}
                                                htmlType="submit"
                                            >
                                                查询
                                            </Button>
                                            <Button
                                                onClick={() => this.selectDate()}
                                                disabled={loading}
                                                loading={loading}
                                            >
                                                重置
                                            </Button>
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    <div
                        style={{
                            width: '100%',
                            background: `#fff`,
                            marginTop: '15px',
                            padding: `0 32px`,
                        }}
                    >
                        <div
                            style={{
                                width: `100%`,
                                color: `rgba(0,0,0,.85)`,
                                lineHeight: `1.5`,
                                fontWeight: 'bold',
                                fontSize: `16px`,
                                padding: `16px 0`,
                            }}
                        >
                            数据汇总
                        </div>
                        <Table
                            columns={columns}
                            dataSource={totalData}
                            pagination={false}
                            summary={this.footerRander}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            background: `#fff`,
                            marginTop: '15px',
                            padding: `0 32px 25px`,
                        }}
                    >
                        <div
                            style={{
                                color: `rgba(0,0,0,.85)`,
                                fontSize: `16px`,
                                fontWeight: 'bold',
                                padding: `16px 0`,
                                lineHeight: `1.5`,
                            }}
                        >
                            每日数据
                        </div>
                        <Table columns={daylyColumns} pagination={false} dataSource={daylyData} />
                    </div>
                </div>
            </>
        );
    }
}

export default Visit;
